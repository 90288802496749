import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';

const Explore = () => {
    return (
        <Layout>
            <h1>Explore by Faction</h1>
            <Factions />
        </Layout>
    )
}

export default Explore;

const ExplorePage = styled.div`

`

const Factions = () => {
    return (
        <FactionsWrap>
            factions here
        </FactionsWrap>
    )
}

const FactionsWrap = styled.section`
    margin-top: 10px;
`